<template>
	<v-slide-group
		v-if="!recordingsLoading"
		:show-arrows="DeviceUtility.isDesktop"
		next-icon="icon-caret-right"
		prev-icon="icon-caret-left"
		class="d-flex">
		<v-slide-item v-for="(recording, i) in recordings" :key="recording.id" class="clickable">
			<div class="video-player">
				<VideoPlayer
					:video-id="videoRecordingId(recording.id)"
					:open-video-light-box="openVideoLightBox"
					:recording-id="recording.id"
					:source="recording.recordingUrl"
					:can-snapshot="true"
					:filename="videoFileName(recording)"
					:controls-enabled="false"
					:thumbnail="true"
					:is-recording-view="true"
					@take-snapshot="() => createSnapshot(recording.id)" />
			</div>
		</v-slide-item>
	</v-slide-group>
	<v-skeleton-loader v-else class="video-skeleton" height="80px" weight="80px" :loading="true" type="image" />
</template>

<script>
	import api from "../../api";
	import timezones from "@/helpers/timezones";
	import VideoPlayer from "@/components/VideoPlayer.vue";
	import CameraShutterClickUrl from "@/assets/camera-shutter-click.mp3";
	import DeviceUtility from "@/helpers/device";

	export default {
		components: {
			VideoPlayer,
		},
		props: {
			isViewOnly: Boolean,
			recordingsLoading: Boolean,
			fileList: Array,
			projectId: String,
			isMobileView: Boolean,
			bulkSelectFiles: Boolean,
			openVideoLightBox: Function,
			selectedFileIds: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				DeviceUtility,
				touchTimeout: null,
				activeFileId: null,
				recordings: [],
				loadingFiles: true,
				isLightboxThumbsOpen: false,
				cols: this.calculateCols(),
				orientationMediaQuery: null,
			};
		},
		methods: {
			async createSnapshot(recordingId) {
				const audio = new Audio(CameraShutterClickUrl);
				audio.play();
				const video = document.getElementById(`video-${recordingId}`);
				await this.createVideoSnapshot(video, recordingId);
			},
			videoFileName(recording) {
				return `file.${this.projectId}.${recording.id}.mp4`;
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			handleTouchStart(file) {
				this.touchTimeout = setTimeout(() => {
					this.activeFileId = this.fileKey(file);
				}, 500); // 500ms for long press
			},
			handleTouchEnd() {
				clearTimeout(this.touchTimeout);
				this.activeFileId = null;
			},
			isActiveFile(file) {
				return this.activeFileId === this.fileKey(file);
			},
			async setFiles() {
				this.recordings = this.fileList;
				this.loadingFiles = false;
			},
			fileKey(file) {
				return file.id;
			},
			getFormattedDate(date) {
				return timezones.formatDate(date, "MMM D, YYYY");
			},
			openFileViewer(fileId) {
				this.$root.$emit("open-file-viewer", fileId);
			},
			isFileSelected(fileId) {
				return this.selectedFileIds.includes(fileId);
			},
			selectFile(file) {
				if (this.bulkSelectFiles) {
					this.$emit("toggle-file-selection", file.id);
				}
			},
			downloadUrl(url) {
				const link = document.createElement("a");
				link.href = url;
				link.click();
			},
			async deleteSelected(id) {
				if (await this.$root.$confirm.deleteOpen("Delete Document", `This will delete the document.`)) {
					try {
						await api.deleteFile(id);
						const index = this.recordings.findIndex((s) => s.id == id);
						this.recordings.splice(index, 1);
					} catch (error) {
						console.error(error);
					}
				}
			},
			documentClass(file) {
				let docClass = "";
				if (this.selectedFileIds.includes(file.id)) {
					docClass += "selected-document";
				}
				if (this.isMobileView) {
					docClass += " background-set";
				}
				return docClass;
			},
			calculateCols() {
				return window.matchMedia("(orientation: landscape)").matches ? 1 : 2;
			},
			updateCols() {
				this.cols = this.calculateCols();
			},
		},
		mounted() {
			this.setFiles();
			console.log(this.fileList);
			this.orientationMediaQuery = window.matchMedia("(orientation: landscape)");
			this.orientationMediaQuery.addEventListener("change", this.updateCols);
		},
		beforeDestroy() {
			this.orientationMediaQuery.removeEventListener("change", this.updateCols);
		},
		watch: {
			fileList: {
				handler() {
					this.setFiles();
				},
				deep: true,
			},
		},
	};
</script>

<style scoped>
	.full-width {
		width: 100%;
	}

	.file-name {
		font-size: 12px;
	}

	.actions {
		display: flex;
		align-items: center;
	}

	.background-set {
		background-color: #f5f5f5;
		margin-top: 10px;
		border-radius: 10px;
	}

	.selected-document {
		border: 1px solid blue;
	}

	.video-player {
		margin: 5px;
		height: 83px;
		min-width: 132px;
		max-width: 132px;
	}

	.v-card__text {
		padding: 0 !important;
	}

	:deep(.v-slide-group__prev) {
		display: flex !important;
	}
	:deep(.v-slide-group__next) {
		display: flex !important;
	}
	:deep(.v-slide-group__prev--disabled) {
		display: none !important;
	}
	:deep(.v-slide-group__next--disabled) {
		display: none !important;
	}
	@media (min-width: 960px) {
		.video-player {
			height: 130px;
			min-width: 190px;
			max-width: 190px;
		}
	}
</style>
