<template>
	<v-snackbar
		v-model="editNotes"
		content-class="edit-notes"
		top
		light
		:timeout="-1"
		:multi-line="true"
		:vertical="true"
		style="z-index: 10020">
		<div class="mt-4" style="font-size: 16px; word-break: break-all; display: flex">
			<v-textarea
				rows="3"
				outlined
				v-model="snapshotNotes"
				:disabled="savingNotes"
				label="Caption"
				counter="200"
				:maxLength="200"
				placeholder="Write a caption..."
				@keydown="editNotesKeyDown"
				@keyup="() => onNotesChanged(snapshotNotes)" />
		</div>
		<div class="edit-notes-controls">
			<v-btn
				color="white"
				@click="
					editNotes = false;
					canSaveNotes = false;
				">
				Back
			</v-btn>
			<v-btn color="primary" :disabled="!canSaveNotes" @click="() => save(snapshotNotes)"> Save </v-btn>
		</div>
	</v-snackbar>
</template>

<script>
	import api from "../api";
	export default {
		name: "SnapshotNotes",
		props: {
			editNotesAllowed: {
				type: Boolean,
				default: false,
			},
			notes: {
				type: String,
				default: "",
			},
			onNotesChanged: {
				type: Function,
				default: () => {},
			},
			canSave: {
				type: Boolean,
				default: false,
			},
			save: {
				type: Function,
			},
		},
		data() {
			return {
				editNotes: this.editNotesAllowed,
				canSaveNotes: false,
				savingNotes: false,
				snapshotNotes: this.notes,
			};
		},
		watch: {
			editNotesAllowed(newVal) {
				this.editNotes = newVal;
				if (!newVal) {
					this.snapshotNotes = "";
				}
			},
			notes(newVal) {
				this.snapshotNotes = newVal;
			},
			canSave(newVal) {
				this.canSaveNotes = newVal;
			},
		},
		mounted() {
			this.editNotes = this.editNotesAllowed;
		},
		methods: {
			async saveNotes() {
				const snapshot = this.snapshots[this.lightBoxIndex];
				let request = { notes: this.snapshotNotes };
				await api.updateSnapshot(snapshot.id, request);

				snapshot.description = this.snapshotNotes;
				this.editNotes = false;
				this.snapshotNotes = "";
				this.canSaveNotes = false;
			},
			editNotesKeyDown(e) {
				if (["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(e.code)) {
					e.stopPropagation();
				}
			},
		},
	};
</script>
<style scoped>
	:deep(.edit-notes) {
		width: 470px;
		max-width: calc(100% - 32px);
		border-radius: 12px;
		margin: 0 auto;
	}

	:deep(.edit-notes textarea) {
		resize: none;
	}

	:deep(.edit-notes-controls) {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	:deep(.edit-notes-controls > button:first-child) {
		margin-right: 15px;
		color: #1e2128;
	}
</style>
