import { beautifyName } from "./utilities";
import moment from "moment";
import api from "@/api";
export function getNotificationTitle(notification) {
	switch (notification.type) {
		case "comment": {
			const comment = notification.metadata.commentCreated;
			if (comment.parentType === "comment") {
				return `${beautifyName(comment.User.name)} replied to your comment.`;
			}
			return `${beautifyName(comment.User.name)} commented on ${getBeautifiedParentName(notification)}.`;
		}
		case "mention": {
			const comment = notification.metadata.commentCreated;
			if (comment.parentType === "comment") {
				return `${beautifyName(comment.User.name)} mentioned you in a reply.`;
			}
			return `${beautifyName(comment.User.name)} mentioned you in a comment.`;
		}
		case "update": {
			const user = notification.metadata?.updatedBy;
			let difference = notification.metadata?.difference;
			difference = cleanDifference(difference);
			if (!user) {
				return `${getBeautifiedParentName(notification)} updated.`;
			}
			if (!difference || difference.length === 0) {
				return `${beautifyName(user.name)} updated ${getBeautifiedParentName(notification)}.`;
			}
			return `${beautifyName(user.name)} ${getOperation(difference)} ${getBeautifiedParentName(
				notification
			)}'s ${getBeautifiedFieldNames(difference, notification.parentType)}.`;
		}
		case "share": {
			const user = notification.metadata?.updatedBy;
			return `${beautifyName(user.firstname)} shared a ${getBeautifiedParentName(
				notification
			)} with you as ${beautifyName(getAccessType(notification))}.`;
		}
		default:
			return `${getBeautifiedParentName(notification)} ${notification.type}.`;
	}
}

function cleanDifference(difference) {
	if (!difference) {
		return {};
	}
	if (Object.keys(difference).length == 1) {
		for (const key of Object.keys(difference)) {
			if (
				difference[key].source &&
				difference[key].target &&
				Array.isArray(difference[key].source) &&
				(difference[key].source.length - difference[key].target.length === 1 ||
					difference[key].source.length - difference[key].target.length === -1)
			) {
				if (key.charAt(key.length - 1) === "s") {
					difference[key.slice(0, -1)] = difference[key];
					delete difference[key];
				}
			}
		}
	}
	return difference;
}

function getOperation(difference) {
	return "updated ";
}

function getBeautifiedParentName(notification) {
	if (notification.parentType === "conversation") {
		return "Session";
	}
	return beautifyName(notification.parentType);
}

function getBeautifiedFieldName(key) {
	switch (key) {
		case "workflowCustomerId":
		case "customerId":
			return " Contact";
		case "projectId":
			return " Project";
		case "conversationId":
			return " Session";
		case "forcedUserId":
			return " User";
		case "addressId":
			return " Address";
		default: {
			const beautifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
			return beautifiedKey
				.replace("Customer", "Contact")
				.replace("Conversation", "Session")
				.replace("ForcedUser", "User");
		}
	}
}

function getBeautifiedFieldNames(difference, parentType) {
	let allNames = Object.keys(difference)
		.filter((key) => (parentType === "project" ? true : key.charAt(0) === key.charAt(0).toLowerCase()))
		.map((key) => `${getBeautifiedFieldName(key)}`);
	// unique
	allNames = [...new Set(allNames)];
	// use & for the last two items
	if (allNames.length > 1) {
		const lastTwo = allNames.slice(-2);
		allNames = allNames.slice(0, -2).concat(`${lastTwo[0]} and ${lastTwo[1]}`);
	}
	return allNames.join(", ");
}

function getAccessType(notification) {
	if (notification.type === "share") {
		if (notification.metadata.projectShare.accessType === "collaborator") {
			return "Contributor";
		}
		return beautifyName(notification.metadata.projectShare.accessType);
	}
	return null;
}

export function getNotificationIcon(notification) {
	switch (notification.parentType) {
		case "project":
			return "icon-form";
		case "artifact": {
			const comment = notification.metadata.commentCreated;
			if (comment.parentType === "photo") {
				return "icon-image";
			}
			return "icon-ls-video-logo";
		}
		case "comment":
			return "icon-comment-stroke";
		case "conversation":
			return "icon-conversation-solid";
		default:
			return "icon-table-stroke";
	}
}

export function getNotificationTime(notification) {
	// relative time
	return moment(notification.createdAt).fromNow();
}

export async function clickNotification(notification, nav) {
	// redirect based on the notification type
	const currentOrgId = api.getOrganizationId();
	await api.updateNotification(notification.id, { isRead: true });
	if (notification.type === "mention" || notification.type === "comment") {
		const comment = notification.metadata.commentCreated;
		const currComment = await api.getComment(comment.id);
		if (currComment.deletedAt) {
			await this.$root.$confirm.open(
				"Comment has been deleted",
				"The comment you are trying to view has been deleted and is no longer available.",
				null,
				null,
				"OK",
				null,
				true,
				false,
				true
			);
			return;
		}
	}
	switch (notification.parentType) {
		case "project":
			nav(`/projects/${notification.parentId}`);
			break;
		case "conversation": {
			try {
				const conversation = await api.getConversationInsecure(notification.parentId);
				if (conversation.organizationId !== currentOrgId) {
					const output = await this.$root.$confirm.open(
						"Switch Organization to View",
						"The notification you are trying to view requires you to switch organizations to view. ",
						null,
						null,
						"Switch Organization",
						"Back",
						true
					);
					if (!output) {
						return;
					}
					api.setOrganizationId(conversation.organizationId);
					localStorage.setItem("login-path", `/conversations/${conversation.id}`);
					this.$auth.login({
						organizationId: conversation.organizationId,
						path: window.location.path,
					});
				}
			} catch (error) {
				await this.$root.$confirm.open(
					"Session has been deleted",
					"The session you are trying to view has been deleted and is no longer available.",
					null,
					null,
					"OK",
					null,
					true,
					false,
					true
				);
				return;
			}
			nav(`/conversations/${notification.parentId}`);
			break;
		}
		case "mention":
		case "comment": {
			const comment = notification.metadata.commentCreated;
			try {
				const commentOriginal = await api.getComment(comment.id);
				if (commentOriginal.deletedAt) {
					throw new Error("Comment deleted");
				}
			} catch (error) {
				await this.$root.$confirm.open(
					"Comment has been deleted",
					"The comment you are trying to view has been deleted and is no longer available.",
					null,
					null,
					"OK",
					null,
					true,
					false,
					true
				);
				return;
			}
			let url = "";
			const conversationId = comment.conversationId;
			let projectId = null;
			let orgId = null;
			let conversation = null;
			if (conversationId) {
				try {
					conversation = await api.getConversationInsecure(conversationId);
					projectId = conversation.projectId;
					orgId = conversation.organizationId;
				} catch (error) {
					orgId = null;
				}
			}
			if (!orgId) {
				orgId = comment.organizationId;
			}
			switch (comment.parentType) {
				case "conversation": {
					if (conversationId && !conversation) {
						await this.$root.$confirm.open(
							"Session has been deleted",
							"The session you are trying to view has been deleted and is no longer available.",
							null,
							null,
							"OK",
							null,
							true,
							false,
							true
						);
						return;
					} else if (!conversationId) {
						try {
							conversation = await api.getConversationInsecure(comment.parentId);
						} catch (error) {
							await this.$root.$confirm.open(
								"Session has been deleted",
								"The session you are trying to view has been deleted and is no longer available.",
								null,
								null,
								"OK",
								null,
								true,
								false,
								true
							);
							return;
						}
					}
					if (orgId !== currentOrgId) {
						const output = await this.$root.$confirm.open(
							"Switch Organization to View",
							"The notification you are trying to view requires you to switch organizations to view. ",
							null,
							null,
							"Switch Organization",
							"Back",
							true
						);
						if (!output) {
							return;
						}
						localStorage.setItem("login-path", `/conversations/${comment.parentId}`);
						api.setOrganizationId(orgId);
						this.$auth.login({
							organizationId: orgId,
							path: window.location.path,
						});
					}
					url = `/conversations/${comment.parentId}`;
					break;
				}
				case "project":
					url = `/projects/${comment.parentId}`;
					break;
				case "video":
				case "photo": {
					if (projectId) {
						url = `/projects/${projectId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
					} else if (conversationId) {
						if (orgId !== currentOrgId) {
							const output = await this.$root.$confirm.open(
								"Switch Organization to View",
								"The notification you are trying to view requires you to switch organizations to view. ",
								null,
								null,
								"Switch Organization",
								"Back",
								true
							);
							if (!output) {
								return;
							}
							localStorage.setItem(
								"login-path",
								`/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`
							);
							api.setOrganizationId(orgId);
							this.$auth.login({
								organizationId: orgId,
								path: window.location.path,
							});
						}
						url = `/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
					}
					break;
				}
				case "comment": {
					const originalComment = await api.getComment(comment.parentId);
					const originalConversationId = originalComment.conversationId;
					let originalProjectId = null;
					let originalOrgId = null;
					if (originalConversationId) {
						try {
							const originalConversation = await api.getConversationInsecure(originalConversationId);
							originalProjectId = originalConversation.projectId;
							originalOrgId = originalConversation.organizationId;
						} catch (error) {
							await this.$root.$confirm.open(
								"Session has been deleted",
								"The session you are trying to view has been deleted and is no longer available.",
								null,
								null,
								"OK",
								null,
								true,
								false,
								true
							);
							return;
						}
					}
					if (!originalOrgId) {
						originalOrgId = originalComment.organizationId;
					}
					switch (originalComment.parentType) {
						case "conversation":
							if (originalOrgId !== currentOrgId) {
								const output = await this.$root.$confirm.open(
									"Switch Organization to View",
									"The notification you are trying to view requires you to switch organizations to view. ",
									null,
									null,
									"Switch Organization",
									"Back",
									true
								);
								if (!output) {
									return;
								}
								localStorage.setItem("login-path", `/conversations/${originalComment.parentId}`);
								api.setOrganizationId(originalOrgId);
								this.$auth.login({
									organizationId: originalOrgId,
									path: window.location.path,
								});
							}
							url = `/conversations/${originalComment.parentId}`;
							break;
						case "project":
							url = `/projects/${originalComment.parentId}`;
							break;
						case "video":
						case "photo":
							{
								if (originalProjectId) {
									url = `/projects/${originalProjectId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`;
								} else if (originalConversationId) {
									if (originalOrgId !== currentOrgId) {
										const output = await this.$root.$confirm.open(
											"Switch Organization to View",
											"The notification you are trying to view requires you to switch organizations to view. ",
											null,
											null,
											"Switch Organization",
											"Back",
											true
										);
										if (!output) {
											return;
										}
										localStorage.setItem(
											"login-path",
											`/conversations/${originalConversationId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`
										);
										api.setOrganizationId(originalOrgId);
										this.$auth.login({
											organizationId: originalOrgId,
											path: window.location.path,
										});
									}
									url = `/conversations/${originalConversationId}?artifactId=${originalComment.parentId}&artifactType=${originalComment.parentType}`;
								}
							}
							break;
						default:
							url = `/projects/${originalComment.parentId}`;
							break;
					}
					break;
				}
				default:
					if (conversationId) {
						url = `/conversations/${conversationId}?artifactId=${comment.parentId}&artifactType=${comment.parentType}`;
					}
					break;
			}
			this.$nextTick(() => {
				nav(url);
			});
			break;
		}
		default:
			break;
	}
}

export function switchOrganization(orgId) {
	api.setOrganizationId(orgId);
	this.$auth.login({
		organizationId: orgId,
		path: window.location.path,
	});
	return true;
}

export async function readNotification(notification) {
	await api.updateNotification(notification.id, { isRead: true });
}
