import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import MeetingActiveIcon from "@/components/icons/MeetingActive.vue";
import FormsIcon from "@/components/icons/Forms.vue";
import RecordButtonIcon from "@/components/icons/RecordButton.vue";
import Record from "@/components/icons/Record.vue";
import MeetingButtonIcon from "@/components/icons/MeetingButton.vue";
import FABIcon from "@/components/icons/FABIcon.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import moment from "moment-timezone";

Vue.use(Vuetify, {});

Vue.filter("formatDate", function (value) {
	if (value) {
		// TODO: i18n
		return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).format("MM/DD/YYYY h:mm A z");
	}
});

Vue.filter("formatDateOnlyLong", function (value) {
	if (value) {
		// TODO: i18n
		return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).format("ddd, MMM D YYYY");
	}
});

Vue.filter("formatDateOnlyShort", function (value) {
	if (value) {
		// TODO: i18n
		return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).format("MMM D YYYY");
	}
});

Vue.filter("formatTimeOnly", function (value) {
	if (value) {
		// TODO: i18n
		return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).format("h:mm A");
	}
});

Vue.filter("formatFromDate", function (value) {
	if (value) {
		// TODO: i18n
		return moment.tz(String(value), "YYYY-MM-DD HH:mm:SS.ii ZZ", moment.tz.guess()).fromNow();
	}
});

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("vue-tel-input", VueTelInput);

// these are supposed to work as a param to vue.use() but it fails no matter what i do
// do i'm giving up and binding inside the control itself, defining here
let defaultCountry = "";
try {
	defaultCountry = StorageWrapper.getItem("defaultCountry");
	if (!defaultCountry) {
		defaultCountry = "US";
	}
	console.log("default", defaultCountry);
} catch {}
window.VueTelProps = {
	preferredCountries: ["US", "AU", "CA", "GB"],
	placeholder: "Phone Number (Required)",
	mode: "international",
	showDialCodeInSelection: true,
	autoDefaultCountry: true,
	dropdownOptions: {
		showFlags: true,
		showDialCodeInSelection: false,
		showSearchBox: true,
	},
	inputOptions: {
		autocomplete: Math.random(),
		autofocus: true,
		id: "",
		maxlength: 16,
		name: "telephone",
		placeholder: "Phone Number (Required)",
		tabindex: "0",
		"aria-describedby": "",
		type: "tel",
	},
	disabledFormatting: false,
	wrapperClasses: "country-phone-input",
	validCharactersOnly: true,
};

const icons = {
	collapse: "icon-chevron-up",
	complete: "icon-check-heavy",
	cancel: "icon-circle-slash",
	close: "icon-close-large",
	delete: "icon-trash-full-stroke",
	clear: "icon-close-circle-stroke",
	calendar: "icon-calendar-check-stroke",
	success: "icon-check-circle",
	info: "icon-info-circle-stroke",
	warning: "icon-warning-triangle",
	error: "icon-warning-star",
	prev: "icon-chevron-left",
	next: "icon-chevron-right",
	sortDesc: "icon-chevron-up",
	sortAsc: "icon-chevron-down",
	checkboxOn: "icon-checkbox-marked-stroke",
	checkboxOff: "icon-checkbox-unmarked-stroke",
	checkboxIndeterminate: "icon-checkbox-marked-solid",
	delimiter: "icon-dots-vertical-more",
	sort: "icon-arrow-down-up",
	expand: "icon-chevron-down",
	menu: "icon-dots-horizontal-more",
	subgroup: "icon-flow-subgroup",
	dropdown: "icon-caret-down",
	radioOn: " icon-radio-filled",
	radioOff: "icon-radio-unfilled",
	edit: "icon-pencil-edit-stroke",
	eyeDropper: "icon-eye-stroke",
	ratingEmpty: "icon-star-rating-empty",
	ratingFull: "icon-star-rating-solid",
	ratingHalf: "icon-star-rating-half",
	loading: "icon-loading-dots-circle",
	first: "icon-chevron-left-end",
	last: "icon-chevron-right-end",
	unfold: "icon-unfold-more",
	file: "icon-file-blank-stroke",
	plus: "icon-plus-circle-stroke",
	puzzle: "icon-puzzle",
	minus: "icon-minus-circle-stroke",
	meetingActive: {
		// name of our custom icon
		component: MeetingActiveIcon, // our custom component
	},
	meetingButton: {
		component: MeetingButtonIcon,
	},
	recordButton: {
		component: RecordButtonIcon,
	},
	record: {
		component: Record,
	},
	forms: {
		component: FormsIcon,
	},
	fab: {
		component: FABIcon,
	},
};

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#2d74ff",
				secondary: colors.grey.darken1,
				accent: "#cad7f8",
				error: colors.red.accent3,
				background: colors.indigo.lighten5, // Not automatically applied
			},
		},
	},
	icons: {
		values: icons,
	},
});
