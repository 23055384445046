import Vue from "vue";
import App from "./App.vue";
import WebForm from "./WebForm.vue";
import AccountSetup from "./AccountSetup.vue";
import vuetify from "./plugins/vuetify";
import auth from "./auth.js";
import router from "./router";
import "./assets/style.css";
import "./assets/styles/animation.css";
import "./assets/styles/liveswitch.css";
import "floating-vue/dist/style.css";
import api from "./api";
import sentry from "./plugins/sentry";
import VueResizeText from "vue-resize-text";
import { IndexBar, IndexAnchor, Cell } from "vant";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const start = async function () {
	// set up process env and api
	const response = await fetch("/config/env.json");
	window.env = await response.json();
	window.api = api;
	Vue.prototype.env = window.env;

	// Add the Google tag manager script to index.html's head tag (but only for the demo and production environments).
	if (window.env.ENVIRONMENT === "demo" || window.env.ENVIRONMENT === "production") {
		const script = document.createElement("script");
		script.innerHTML =
			"" +
			"(function(w, d, s, l, i) {\n" +
			"  w[l] = w[l] || [];\n" +
			"  w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});\n" +
			"  const f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';\n" +
			"  j.async = true;\n" +
			"  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;\n" +
			"  f.parentNode.insertBefore(j, f);\n" +
			"})(window, document, 'script', 'dataLayer', 'GTM-NBNG9T3')";
		document.head.appendChild(script);
	}

	// Add the HubSpot chatbot script to index.html's body tag (but only for the demo environment).
	if (window.env.ENVIRONMENT === "demo") {
		const script = document.createElement("script");
		script.src = "//js.hs-scripts.com/3919779.js";
		document.body.appendChild(script);
	}

	// app uses auth
	Vue.use(auth);
	// sentry after auth so we get access to the user record
	Vue.use(sentry);

	Vue.config.productionTip = false;
	Vue.use(VueResizeText);
	Vue.use(IndexBar);
	Vue.use(IndexAnchor);
	Vue.use(Cell);

	Vue.use(VuetifyGoogleAutocomplete, {
		apiKey: window.env.VITE_GOOGLE_MAPS_API_KEY,
		vueGoogleMapsCompatibility: false,
	});
	Vue.use(VueToast, {
		position: 'top',
		duration: 3200,
		dismissible: false,
		pauseOnHover: false,
		stackable: true
	});

	// allows us to bypass authentication and send the visitor
	// directly to the self service async form
	if (window.location.pathname == "/form") {
		new Vue({
			vuetify,
			render: (h) => h(WebForm),
		}).$mount("#app");
	} else if (window.location.pathname == "/account-setup") {
		new Vue({
			vuetify,
			render: (h) => h(AccountSetup),
		}).$mount("#app");
	} else {
		new Vue({
			vuetify,
			router,
			render: (h) => h(App),
		}).$mount("#app");
	}
};

start();
