import { AccountSetupRequest } from "@/types/payments/AccountSetupRequest";
import { AccountSetupResponse } from "@/types/payments/AccountSetupResponse";
import { SubscriptionInfo } from "@/types/payments/SubscriptionInfo";
import * as Sentry from "@sentry/browser";

const getSubscriptionInfo = async (paymentProvider: string, sessionId: string): Promise<SubscriptionInfo | null> => {
    const url = `${(window as any).env.VITE_SUBSCRIPTIONS_URL}/api`;
    try {
        let response = await fetch(`${url}/v1/accountsetup/${paymentProvider}/session/${sessionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "X-API-Key": (window as any).env.VITE_SUBSCRIPTIONS_API_KEY
            }
        })

        if (response != null && response.ok) {
            return await response.json() as SubscriptionInfo;
        }
    } catch (err: any) {
        Sentry.captureException(err, {
            extra: {
                paymentProvider: paymentProvider,
                sessionId: sessionId
            },
            tags: {
                file: "payment",
                method: "getSubscriptionInfo"
            }
        });
    }

    return null;
}

const setupAccount = async (request: AccountSetupRequest): Promise<AccountSetupResponse> => {
    const url = `${(window as any).env.VITE_SUBSCRIPTIONS_URL}/api`;

    try {
        let response = await fetch(`${url}/v1/accountsetup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-API-Key": (window as any).env.VITE_SUBSCRIPTIONS_API_KEY
            },
            body: JSON.stringify(request)
        })

        if (response != null && response.ok) {
            return await response.json() as AccountSetupResponse;
        }
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                subscriptionTransactionId: request.subscriptionTransactionId
            },
            tags: {
                file: "payment",
                method: "setupAccount"
            }
        });
    }

    return {
        error: true,
        message: "Failed to setup account. API request failed",
        subscriptionTransactionId: request.subscriptionTransactionId
    };
};

export { getSubscriptionInfo, setupAccount }