class StorageWrapper {
    private storage: Storage;

    constructor() {
        this.storage = this.isLocalStorageAvailable() ? localStorage : sessionStorage;
    }

    private isLocalStorageAvailable(): boolean {
        try {
            const testKey = '__test__';
            localStorage.setItem(testKey, testKey);
            const returned = localStorage.getItem(testKey);
            localStorage.removeItem(testKey);
            return returned == testKey;
        } catch (e) {
            return false;
        }
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    clear(): void {
        this.storage.clear();
    }
}

export default new StorageWrapper();