<template>
	<div class="artifact-page">
		<div
			class="d-flex justify-space-between"
			v-if="!(artifactCreatedDateMappingsMap && Object.keys(artifactCreatedDateMappingsMap).length)">
			No {{ getArtifactName(artifactType) }}
			<v-btn size="x-small" class="close-button" color="transparent" elevation="0" @click="closeModal"
				><v-icon>icon-close-medium</v-icon></v-btn
			>
		</div>
		<div v-if="artifactCreatedDateMappingsMap && Object.keys(artifactCreatedDateMappingsMap).length">
			<div class="d-flex justify-space-between heading">
				{{ getArtifactName(artifactType) }}
				<v-btn size="x-small" class="close-button" color="transparent" elevation="0" @click="closeModal"
					><v-icon>icon-close-medium</v-icon></v-btn
				>
			</div>
			<div v-if="artifacts.length > 0" class="d-flex justify-space-between action-buttons">
				<div class="d-flex justify-space-center mb-3">
					<v-btn
						v-if="bulkSelect || artifactType === 'files'"
						class="white--text view-button"
						color="#BCAAA4"
						@click="toggleBulkSelect"
						elevation="0"
						>{{ bulkSelect ? `Back` : artifacts.length == 1 ? "Select" : "Bulk Select" }}
					</v-btn>
					<v-btn
						v-if="bulkSelect && artifacts.length > 1"
						class="white--text view-button"
						color="#BCAAA4"
						@click="selectAll"
						elevation="0"
						>{{ artifacts.length == bulkSelectedIds.length ? "Deselect All" : "Select All" }}</v-btn
					>
				</div>
				<div v-if="bulkSelect && bulkSelectedIds.length > 0" class="d-flex justify-space-center">
					<v-tooltip top :open-on-focus="false" :disabled="nonProcessedSelected == 0">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-btn elevation="0" icon @click="downloadArtifacts" :disabled="nonProcessedSelected > 0">
									<v-icon v-if="!downloading" size="20" color="black">icon-download</v-icon>
									<v-progress-circular
										v-else
										indeterminate
										color="primary"
										size="20"
										class="progress-icon"></v-progress-circular>
								</v-btn>
							</div>
						</template>
						<span> {{ bulkSelectedIds.length > 1 ? "Apologies. It appears one of the videos you are trying to download has not finished processing. Please wait for the video to process and then download." : "Apologies. It appears the video you are trying to download has not finished processing. Please wait for the video to process and then download." }}	</span>
					</v-tooltip>
					<v-btn
						elevation="0"
						v-if="!(isViewOnly && !hasFreeTrialEnded) && bulkSelectedIds.length > 0"
						icon
						@click="() => deleteArtifacts(artifactType, bulkSelectedIds)">
						<v-icon size="20" color="red">icon-trash-full-stroke</v-icon>
					</v-btn>
				</div>
			</div>
			<div v-for="(artifacts, i) in artifactCreatedDateMappingsMap" :key="i" class="datemapping">
				<div class="date">{{ i }}</div>
				<DocumentView
					v-if="artifactType == 'files'"
					:file-list="artifacts"
					:is-mobile-view="$vuetify.breakpoint.smAndDown"
					:full-view="true"
					:is-view-only="isViewOnly && !hasFreeTrialEnded"
					:selected-file-ids="bulkSelectedIds"
					:bulk-select-files="bulkSelect"
					@toggle-file-selection="toggleFileSelection"
					@delete-file="deleteFile" />
				<v-list-item-group v-else class="d-flex w-100">
					<template>
						<v-container fluid class="container">
							<v-row>
								<v-col
									v-for="(artifact, index) in artifacts"
									:key="artifact.id"
									cols="4"
									lg="2"
									class="column">
									<v-list-item :ripple="false">
										<div class="video-player" @click.stop="clickItem(artifact.id)">
											<div class="w-100 border-add" v-if="artifactType == 'recordings'">
												<VideoPlayer
													:video-id="videoRecordingId(artifact.id)"
													:open-video-light-box="bulkSelect ? () => {} : openVideoLightBox"
													:recording-id="artifact.id"
													:source="artifact.recordingUrl"
													:can-snapshot="true"
													:limit-height="true"
													:is-selected="isSnapshotSelected(artifact.id)"
													:filename="videoFileName(artifact)"
													:thumbnail="true"
													:controls-enabled="false"
													@take-snapshot="() => createSnapshot(artifact.id)" />
												<div
													v-if="!isLightBoxOpen"
													class="selection-icon-video"
													@click.stop="updateBulkSelect(artifact.id)">
													<div
														class="circle-video"
														:class="{ selected: isSnapshotSelected(artifact.id) }">
														<span
															class="text-h5 white--text centre-number"
															color="green"
															v-if="isSnapshotSelected(artifact.id)">
															{{ getSnapshotSelectionIndex(artifact.id) }}
														</span>
													</div>
												</div>
											</div>
											<div class="" v-else-if="artifactType == 'snapshots'">
												<v-img
													:src="artifact.src"
													:class="
														bulkSelectedIds.includes(artifact.id) ? 'artifact-item' : null
													"
													class="image-artifact"
													height="90px"
													max-height="78px"
													max-width="117px"
													:lazy-src="artifact.src"
													crossorigin="anonymous">
													<template v-slot:placeholder>
														<v-row class="fill-height ma-0" align="center" justify="center">
															<v-progress-circular
																indeterminate
																color="grey lighten-5"></v-progress-circular>
														</v-row>
													</template>
													<div
														class="selection-icon"
														:style="{ 'z-index': '1000' }"
														@click.stop="updateBulkSelect(artifact.id)">
														<div
															class="circle"
															:class="{ selected: isSnapshotSelected(artifact.id) }">
															<span
																class="text-h5 white--text centre-number"
																color="green"
																v-if="isSnapshotSelected(artifact.id)">
																{{ getSnapshotSelectionIndex(artifact.id) }}
															</span>
														</div>
													</div>

													<!-- Overlay to indicate selection -->
													<v-overlay
														absolute
														:opacity="0.72"
														@click.stop="
															isSnapshotSelected(artifact.id)
																? updateBulkSelect(artifact.id)
																: openLightBox(artifact.id)
														"
														:value="isSnapshotSelected(artifact.id)"></v-overlay>
												</v-img>
											</div>
											<div v-else class="file-icon">
												<div class="document">
													<div class="document-edge"></div>
													<div class="document-content">
														<div class="file-name">
															{{ artifact.extension.toUpperCase() }}
														</div>
													</div>
												</div>
												<div class="file-type">
													{{ artifact.title }}
												</div>
											</div>
										</div>
									</v-list-item>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</v-list-item-group>
			</div>
			<v-dialog
				ref="videosDialog"
				fullscreen
				content-class="no-scroll"
				v-model="snapshotLightBoxOpen"
				persistent
				no-click-animation>
				<LightBox
					ref="lightBox"
					:is-view-only="isViewOnly || isContributor"
					:is-contributor="isContributor"
					:snapshots="snapshots"
					:open-light-box="openLightBox"
					:show-gallery="false"
					:me="me"
					:users="users"
					:has-free-trial-ended="hasFreeTrialEnded"
					:parent-id="projectId"
					@update:isLightboxThumbsOpen="toggleThumbnails"
					@download-artifacts="downloadSelected"
					@delete-artifacts="deleteArtifacts"
					@close-lightbox="snapshotLightBoxOpen = false" />
			</v-dialog>

			<v-dialog
				v-if="videoLightBoxOpen"
				ref="videosDialog"
				content-class="no-scroll"
				fullscreen
				v-model="videoLightBoxOpen"
				persistent
				no-click-animation>
				<VideoLightBox
					v-if="artifactType == 'recordings'"
					ref="videoLightBox"
					:is-view-only="isViewOnly || isContributor"
					:has-free-trial-ended="hasFreeTrialEnded"
					:is-contributor="isContributor"
					:parent-id="projectId"
					:open-light-box="openVideoLightBox"
					:user="user"
					:recordings="recordings"
					@take-snapshot="createLightBoxSnapshot"
					@share="share"
					:me="me"
					:users="users"
					@delete-recording="deleteRecording"
					@delete-artifacts="deleteArtifacts"
					@update:isLightboxThumbsOpen="toggleThumbnails"
					@close-lightbox="videoLightBoxOpen = false" />
			</v-dialog>
		</div>
	</div>
</template>
<script>
	import moment from "moment-timezone";
	import timezones from "@/helpers/timezones";
	import CameraShutterClickUrl from "@/assets/camera-shutter-click.mp3";
	import { ArtifactType } from "@/enums/ArtifactType";
	import LightBox from "./LightBox.vue";
	import DocumentView from "./artifacts/DocumentView.vue";
	import { downloadAsZip } from "@/helpers/download";
	import VideoPlayer from "@/components/VideoPlayer.vue";
	import { downloadFile } from "@/helpers/download";
	import { file } from "jszip";

	export default {
		components: {
			LightBox,
			DocumentView,
			VideoPlayer,
		},
		props: {
			recordings: Array,
			snapshots: Array,
			files: Array,
			projectId: String,
			projectName: String,
			sessionName: String,
			createSnapshot: Function,
			artifactType: String,
			isViewOnly: Boolean,
			isContributor: Boolean,
			createVideoSnapshot: Function,
			share: Function,
			user: Object,
			me: Object,
			hasFreeTrialEnded: Boolean,
			users: Array,
		},
		data() {
			return {
				bulkSelect: false,
				bulkSelectedIds: [],
				downloading: false,
				isLightBoxOpen: false,
				videoLightBoxOpen: false,
				snapshotLightBoxOpen: false,
			};
		},
		watch: {
			bulkSelectedIds(val) {
				if (val.length == 0) {
					this.bulkSelect = false;
				} else {
					this.bulkSelect = true;
				}
			},
		},
		methods: {
			async deleteRecording(id) {
				this.$emit("delete-recording", id);
			},
			async downloadSelected(artifactType, selectedIds) {
				this.$emit(
					"download-artifacts",
					artifactType ?? this.artifactType,
					selectedIds ?? this.bulkSelectedIds
				);
			},
			async deleteFile(id) {
				this.bulkSelectedIds = this.bulkSelectedIds.filter((x) => x !== id);
				this.$emit("delete-file", id);
			},
			async clickItem(id) {
				if (this.bulkSelect) {
					if (this.artifactType == "snapshots") {
						if (!this.isSnapshotSelected(id)) {
							this.openLightBox(id);
						} else {
							this.updateBulkSelect(id);
						}
					} else if (this.artifactType == "recordings") {
						if (this.isSnapshotSelected(id)) {
							this.updateBulkSelect(id);
						} else {
							this.openVideoLightBox(id);
						}
					}
				} else if (this.artifactType == "snapshots") {
					this.openLightBox(id);
				} else if (this.artifactType == "recordings") {
					this.openVideoLightBox(id);
				}
			},
			async createLightBoxSnapshot(video, recordingId) {
				const audio = new Audio(CameraShutterClickUrl);
				audio.play();
				await this.createVideoSnapshot(video, recordingId);
			},
			getSnapshotSelectionIndex(snapshotId) {
				return this.bulkSelectedIds.indexOf(snapshotId) + 1;
			},
			toggleThumbnails(val) {
				this.isLightboxThumbsOpen = !!val;
			},
			toggleFileSelection(fileId) {
				if (this.bulkSelectedIds.includes(fileId)) {
					this.bulkSelectedIds = this.bulkSelectedIds.filter((id) => id !== fileId);
				} else {
					this.bulkSelectedIds.push(fileId);
				}
			},
			isSnapshotSelected(id) {
				return this.bulkSelectedIds.findIndex((x) => x == id) !== -1;
			},
			downloadUrl(url) {
				const link = document.createElement("a");
				link.href = url;
				link.download = url.substring(url.lastIndexOf("/") + 1);
				link.click();
			},
			selectAll() {
				const artifacts = this.getArtifacts();
				if (this.bulkSelectedIds.length == artifacts.length) {
					this.bulkSelectedIds = [];
				} else {
					this.bulkSelectedIds = [];
					this.bulkSelectedIds.push(...artifacts.map((s) => s.id));
				}
			},
			async downloadArtifacts() {
				const artifacts = this.getArtifacts();
				const artifactUrls = artifacts
					.filter((artifact) => this.bulkSelectedIds.includes(artifact.id))
					.map((s) => s.src);
				if (artifactUrls.length == 1) {
					return await this.downloadArtifactUrl(artifactUrls[0]);
				}
				this.downloading = true;
				if (this.artifactType == ArtifactType.Files) {
					const artifactTitles = artifacts
						.filter((artifact) => this.bulkSelectedIds.includes(artifact.id))
						.map((s) => s.title);
					await downloadAsZip(artifactUrls, this.projectName ?? this.sessionName, "files", artifactTitles);
				} else {
					await downloadAsZip(artifactUrls, this.projectName ?? this.sessionName, "photos", []);
				}
				this.downloading = false;
			},
			async openLightBox(id) {
				if (this.bulkSelect && this.artifactType !== "snapshots") return;
				this.snapshotLightBoxOpen = true;
				await this.$nextTick(() => {
					this.$refs.lightBox.setCurrentSnapshotId(id);
				});
			},
			async openVideoLightBox(id) {
				if (this.bulkSelect && this.artifactType !== "recordings") return;
				this.videoLightBoxOpen = true;
				await this.$nextTick(() => {
					this.$refs.videoLightBox.setCurrentRecordingId(id);
				});
			},
			getArtifacts() {
				if (this.artifactType == ArtifactType.Recordings) {
					return this.recordings.map((recording) => ({
						...recording,
						src: recording.recordingUrl,
					}));
				} else if (this.artifactType == ArtifactType.Snapshots) {
					return this.snapshots;
				} else if (this.artifactType == ArtifactType.Files) {
					return this.files;
				}
			},
			async downloadArtifactUrl(url) {
				let fileName = url.split("?")[0].substring(url.lastIndexOf("/") + 1);
				downloadFile(url, fileName);
			},
			deleteArtifacts(artifactType, selectedIds, callback) {
				callback =
					callback ??
					(() => {
						this.bulkSelectedIds = [];
					});
				this.$emit("delete", artifactType ?? this.artifactType, selectedIds ?? this.bulkSelectedIds, callback);
			},
			updateBulkSelect(id) {
				if (this.bulkSelectedIds.includes(id)) {
					this.bulkSelectedIds = this.bulkSelectedIds.filter((i) => i !== id);
				} else {
					this.bulkSelectedIds.push(id);
				}
			},
			toggleBulkSelect() {
				this.bulkSelect = !this.bulkSelect;
				if (!this.bulkSelect) {
					this.bulkSelectedIds = [];
				} else if (this.artifacts.length == 1) {
					this.bulkSelectedIds = [this.artifacts[0].id];
				}
			},
			videoFileName(recording) {
				return `recording.${this.projectId}.${recording.id}.mp4`;
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			formatRecordingDate(recording) {
				return moment(recording.recordingStart).fromNow();
			},
			closeModal() {
				this.$emit("close");
			},
			closeGallery() {
				this.$refs.lightBox.hideThumbnailTray();
			},
			getArtifactName(artifactType) {
				if (artifactType == ArtifactType.Recordings) {
					return "Videos";
				} else if (artifactType == ArtifactType.Snapshots) {
					return "Photos";
				} else if (artifactType == ArtifactType.Files) {
					return "Documents";
				}
				return "";
			},
		},
		computed: {
			nonProcessedSelected() {
				if (this.artifactType !== ArtifactType.Recordings) {
					return 0;
				}
				let count = 0;
				for (const recording of this.recordings) {
					if (this.bulkSelectedIds.includes(recording.id) && recording.recordingStatus !== "READY") {
						count++;
					}
				}
				return count;
			},
			artifacts() {
				if (this.artifactType == ArtifactType.Recordings) {
					return [...this.recordings].sort((a, b) => new Date(b.recordingStart) - new Date(a.recordingStart));
				} else if (this.artifactType == ArtifactType.Snapshots) {
					return [...this.snapshots].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
				} else if (this.artifactType == ArtifactType.Files) {
					return [...this.files].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
				}
				return [];
			},
			artifactCreatedDateMappingsMap() {
				const mappings = {};
				if (this.artifactType == ArtifactType.Recordings) {
					this.artifacts.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.recordingStart, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				} else if (this.artifactType == ArtifactType.Snapshots) {
					this.artifacts.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.createdAt, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				} else if (this.artifactType == ArtifactType.Files) {
					this.artifacts.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.createdAt, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				}
				return [];
			},
			isFullScreen() {
				return (
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitCurrentFullScreenElement
				);
			},
		},
	};
</script>
<style scoped>
	.video-player {
		box-shadow: none !important;
	}
	.border-add {
		border-radius: 16px;
	}
	.selected {
		background-color: green !important;
		opacity: 1 !important;
		border-color: green !important;
	}

	.selection-icon {
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;
		z-index: 3;
	}
	.selection-icon-video {
		cursor: pointer;
		position: absolute;
		top: 20%;
		right: 20%;
		z-index: 3;
	}
	@media (min-width: 960px) {
		.image-artifact {
			height: 100px !important;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
		.date {
			padding-top: 16px;
			padding-bottom: 16px;
		}
		.artifact-page {
			padding-left: 8px;
		}
		.column {
			max-width: 13.33%;
		}
	}
	.container {
		padding: 0px !important;
		height: 100%;
	}
	.heading {
		font-size: 24px;
		font-weight: 600;
		margin: 4px;
	}
	.date {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 13px;
		margin-top: 13px;
	}
	.video-player {
		height: 100%;
		width: 100%;
		max-height: 78px;
	}

	.column {
		padding-left: 0.5rem;
		padding-bottom: 0rem;
		padding-top: 0rem;
	}

	.close-button {
		padding-left: 0px !important;
		padding-right: 0px !important;
		max-width: 24px !important;
		min-width: 24px !important;
	}

	.view-button {
		height: 22px !important;
		width: 75px !important;
		font-size: 12px !important;
		border-radius: 8px;
		margin-right: 4px;
		margin-top: 4px;
	}

	.action-buttons {
		margin-bottom: 7px;
		margin-top: 28px;
	}

	.overlay {
		position: relative;
		background-color: transparent;
		border-radius: 20px;
		z-index: 1;
	}
	.bulk-selected {
		border-radius: 10px !important;
		border-width: 50px !important;
		border-color: blue !important;
		background-color: blue;
	}

	.artifact-item {
		padding: 0;
		border: 2px solid blue;
		margin-bottom: 8px;
	}

	.v-list-item {
		padding: 0;
	}
	.row {
		padding-right: 10px;
	}

	.document {
		width: 90px;
		height: 60px;
		background-color: #f0f0f0;
		border: 2px solid #ccc;
		border-radius: 3px;
		margin-right: 2px;
	}

	.project-readonly {
		padding: 16px !important;
	}

	.document-edge {
		width: 0px;
		height: 0px;
		border-top: 20px solid #ccc;
		border-right: 20px solid transparent;
		top: -2px;
		right: -2px;
	}

	.document-content {
		width: 40px !important;
		text-align: center;
	}

	.file-type {
		font-size: 10px;
		margin-bottom: 10px;
		max-width: 120px;
		font-weight: bold;
		color: #666;
		white-space: wrap;
		line-height: 1.375;
		word-break: break-word;
	}

	.file-icon {
		margin-bottom: 200px;
	}

	.file-name {
		font-size: 12px;
	}
	.datemapping {
		height: inherit;
	}
	.row {
		margin-left: 0px;
		margin-right: 0px;
	}

	.v-card {
		padding: 10px;
		height: 100%;
		border-radius: 16px;
		width: 100%;
	}

	.artifact-page {
		width: 100%;
	}
	.v-list-item--active:hover::before,
	.v-list-item--active::before,
	.v-list-item:hover::before {
		opacity: 0 !important;
	}
</style>
